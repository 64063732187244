import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocFooter from "../../components/api-doc-footer"

const ApiListVoicesPage = () => (
  <Layout title="List Voices API" sidebar={ApiSidebar()}>
    <SEO title="List Voices API" />
    <p>...</p>
    <ApiDocFooter active="/api/list-voices/" />
  </Layout>
)

export default ApiListVoicesPage
